#contact {
	width: 100%;
}

.contactMethods {
	display: flex;
	align-items: baseline;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
}

.contactMethods .fa-6x {
	margin: 20px 10px;
}

.contactBox {
	display: flex;
	margin: 0 10px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: row wrap;
	font-size: calc(10px + 1vmin);
}