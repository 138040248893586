#errorPage {
	width: 100%;
}

#errorPage h1 {
	font-size: calc(50px + 15vmin);
}

#errorPage p {
	color: gray;
}