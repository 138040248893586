#stats {
	width: 100%;
}

.bot-logo {
	height: 50vmin;
	border-radius: 30px;
	z-index: 2;
}

.chartContainer {
	background-color: white;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.chartCollection {
	width: 80%;
	color: black;
	flex-direction: column;
}

.chartCollection div {
	margin: 25px 0;
}

.alert {
	border-radius: 0;
}

#chartAlert {
	display: flex;
	position: fixed;
	width: 100%;
	bottom: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	z-index: +1;
}

#chartAlert .alert-success {
	animation: slide 1s 1 linear forwards;
}

#chartAlert .alert-success {
	animation: slide 3s 1 linear forwards;
}

#chart1, #chart2, #chart3, #chart4 {
	width: 100%;
}

.canvasjs-chart-canvas {
	
}

@keyframes slide {
	0% {
		left: 0%;
		opacity: 100%;
	}
	50% {
		left: 0%;
		opacity: 100%;
	}
	100% {
		left: 100%;
		max-height: 0%;
		opacity: 0%;
		overflow: hidden;
		visibility: hidden;
		display: none;
	}
}
@-webkit-keyframes slide {
	0% {
		left: 0%;
		opacity: 1;
	}
	50% {
		left: 0%;
		opacity: 1;
	}
	99% {
		left: 99%;
		opacity: 1;
	}
	100% {
		left: 100%;
		opacity: 0;
		display: none;
	}
}