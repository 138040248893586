body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
	background: transparent;
}

.popup-content {
	height: 100%;
	width: 100%;
	display: inline-block;
	flex-direction: column;
}