#home {
	width: 100%;
}

.mySelfContainer {
	background-color: white;
}

.mySelf {
	background-color: white;
	min-height: 30vh;
	max-width: 50%;
	display: flex;
	flex-direction: column;
	margin: 25px auto;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 1vmin);
	color: black;
}

.mySelf p {
	margin: 20px 0 20px 0;
}

.myProjects {
	background-color: #282c34;
	display: flex;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
	font-size: calc(10px + 1vmin);
	color: white;
}

.myProjects h1 {
	margin: 25px;
}

.project {
	background-color: white;
	min-width: 300px;
	max-width: 40%;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	margin: 15px;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.project img {
	max-width: 30%;
	align-items: left;
	border-radius: 30px;
	justify-content: left;
	margin: 25px;
}