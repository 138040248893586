#bots {
	width: 100%;
}

.myBots {
	display: flex;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
	font-size: calc(10px + 1vmin);
	color: white;
}

.bot {
	background-color: white;
	min-width: 300px;
	max-width: 40%;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	margin: 15px;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.bot img {
	max-width: 30%;
	align-items: left;
	border-radius: 30px;
	justify-content: left;
	margin: 25px;
}

.bot hr {
	width: 100%;
}

.bot button {
	margin-top: 10%;
	margin-bottom: 5px;
}

.BotInfoCotainer {
    border: none;
	height: 100%;
	width: 100%;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;
	overflow: auto;
	z-index: -1;
}

.BotInfo {
	background-color: white;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.BotInfoHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.BotInfoHeader img {
	align: left;
	max-width: 128px;
	max-height: 128px;
	border-radius: 25px;
	margin: 25px;
}

.BotInfoHeader p {
	max-width: 320px;
}

.BotInfo button {
	margin-top: 10%;
	margin-bottom: 10px;
}

.tgbutton {
	background-color: cyan;
}

.offlineCircle {
	color: red;
	animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    color: #282c34;
  }
  50% {
    color: #FF4136;
  }
  100% {
    color: #282c34;
  }
}