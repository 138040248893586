body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
	background: transparent;
}

.popup-content {
	height: 100%;
	width: 100%;
	display: inline-block;
	flex-direction: column;
}
* {
    margin: 0;
    padding: 0;
    border: none;
}

html {
	background-color: #282c34;
}

.navbar {
	background-color: #282c34;
	display: flex;
	position: fixed;
	width: 100%;
	top: 0;
}

.App {
	background-color: #282c34;
	width: 100%;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;
	position: absolute;
	overflow: auto;
	padding-top: 8vmin;
	z-index: -1;
}

.logo {
	height: 50vmin;
	z-index: 2;
}

.bottom-logo {
	height: 50vmin;
	z-index: 1;
}

.mergeLogo{
	position: relative;
}

h1, h2, h3, h4, h5 {
	color: #9d383e;
}

a {
	text-decoration: none;
}

.header {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.header b {
	color: #9d383e;
}

#btn {
	background-color: #9d383e;
}

.button {
	background-color: #9d383e;
	border: none;
	transition-duration: 0.5s;
}

.button:hover {
	background-color: #602227;
}

.footerSpace {
	background-color: #282c34;
	display: flex;
	width: 100%;
	bottom: 0;
    clear: both;
	flex-direction: column;
	flex: 1 1 auto;
	color: white;
}
#home {
	width: 100%;
}

.mySelfContainer {
	background-color: white;
}

.mySelf {
	background-color: white;
	min-height: 30vh;
	max-width: 50%;
	display: flex;
	flex-direction: column;
	margin: 25px auto;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 1vmin);
	color: black;
}

.mySelf p {
	margin: 20px 0 20px 0;
}

.myProjects {
	background-color: #282c34;
	display: flex;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
	font-size: calc(10px + 1vmin);
	color: white;
}

.myProjects h1 {
	margin: 25px;
}

.project {
	background-color: white;
	min-width: 300px;
	max-width: 40%;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	margin: 15px;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.project img {
	max-width: 30%;
	align-items: left;
	border-radius: 30px;
	justify-content: left;
	margin: 25px;
}
#bots {
	width: 100%;
}

.myBots {
	display: flex;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
	font-size: calc(10px + 1vmin);
	color: white;
}

.bot {
	background-color: white;
	min-width: 300px;
	max-width: 40%;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	margin: 15px;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.bot img {
	max-width: 30%;
	align-items: left;
	border-radius: 30px;
	justify-content: left;
	margin: 25px;
}

.bot hr {
	width: 100%;
}

.bot button {
	margin-top: 10%;
	margin-bottom: 5px;
}

.BotInfoCotainer {
    border: none;
	height: 100%;
	width: 100%;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;
	overflow: auto;
	z-index: -1;
}

.BotInfo {
	background-color: white;
	border-radius: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 15px;
	font-size: calc(10px + 1vmin);
	color: black;
}

.BotInfoHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.BotInfoHeader img {
	align: left;
	max-width: 128px;
	max-height: 128px;
	border-radius: 25px;
	margin: 25px;
}

.BotInfoHeader p {
	max-width: 320px;
}

.BotInfo button {
	margin-top: 10%;
	margin-bottom: 10px;
}

.tgbutton {
	background-color: cyan;
}

.offlineCircle {
	color: red;
	-webkit-animation: pulse 1s infinite;
	        animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
  0% {
    color: #282c34;
  }
  50% {
    color: #FF4136;
  }
  100% {
    color: #282c34;
  }
}

@keyframes pulse {
  0% {
    color: #282c34;
  }
  50% {
    color: #FF4136;
  }
  100% {
    color: #282c34;
  }
}
#stats {
	width: 100%;
}

.bot-logo {
	height: 50vmin;
	border-radius: 30px;
	z-index: 2;
}

.chartContainer {
	background-color: white;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.chartCollection {
	width: 80%;
	color: black;
	flex-direction: column;
}

.chartCollection div {
	margin: 25px 0;
}

.alert {
	border-radius: 0;
}

#chartAlert {
	display: flex;
	position: fixed;
	width: 100%;
	bottom: 0;
	text-align: center;
	align-items: center;
	justify-content: center;
	z-index: +1;
}

#chartAlert .alert-success {
	-webkit-animation: slide 1s 1 linear forwards;
	        animation: slide 1s 1 linear forwards;
}

#chartAlert .alert-success {
	-webkit-animation: slide 3s 1 linear forwards;
	        animation: slide 3s 1 linear forwards;
}

#chart1, #chart2, #chart3, #chart4 {
	width: 100%;
}

.canvasjs-chart-canvas {
	
}

@keyframes slide {
	0% {
		left: 0%;
		opacity: 100%;
	}
	50% {
		left: 0%;
		opacity: 100%;
	}
	100% {
		left: 100%;
		max-height: 0%;
		opacity: 0%;
		overflow: hidden;
		visibility: hidden;
		display: none;
	}
}
@-webkit-keyframes slide {
	0% {
		left: 0%;
		opacity: 1;
	}
	50% {
		left: 0%;
		opacity: 1;
	}
	99% {
		left: 99%;
		opacity: 1;
	}
	100% {
		left: 100%;
		opacity: 0;
		display: none;
	}
}
#contact {
	width: 100%;
}

.contactMethods {
	display: flex;
	align-items: baseline;
	justify-content: center;
	flex-wrap:  wrap;
	max-width: 100%;
}

.contactMethods .fa-6x {
	margin: 20px 10px;
}

.contactBox {
	display: flex;
	margin: 0 10px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: row wrap;
	font-size: calc(10px + 1vmin);
}
#about {
	width: 100%;
}

.aboutContainer {
	background-color: white;
}


#errorPage {
	width: 100%;
}

#errorPage h1 {
	font-size: calc(50px + 15vmin);
}

#errorPage p {
	color: gray;
}
