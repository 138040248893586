* {
    margin: 0;
    padding: 0;
    border: none;
}

html {
	background-color: #282c34;
}

.navbar {
	background-color: #282c34;
	display: flex;
	position: fixed;
	width: 100%;
	top: 0;
}

.App {
	background-color: #282c34;
	width: 100%;
	text-align: center;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;
	position: absolute;
	overflow: auto;
	padding-top: 8vmin;
	z-index: -1;
}

.logo {
	height: 50vmin;
	z-index: 2;
}

.bottom-logo {
	height: 50vmin;
	z-index: 1;
}

.mergeLogo{
	position: relative;
}

h1, h2, h3, h4, h5 {
	color: #9d383e;
}

a {
	text-decoration: none;
}

.header {
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.header b {
	color: #9d383e;
}

#btn {
	background-color: #9d383e;
}

.button {
	background-color: #9d383e;
	border: none;
	transition-duration: 0.5s;
}

.button:hover {
	background-color: #602227;
}

.footerSpace {
	background-color: #282c34;
	display: flex;
	width: 100%;
	bottom: 0;
    clear: both;
	flex-direction: column;
	flex: 1 1 auto;
	color: white;
}